<template>
    <div>
        <el-dialog
            title="添加站点标签"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item label="所属商户" prop="companyId">
                    <le-company-under-select style="padding: 0" label="" v-model="form.companyId"></le-company-under-select>
                </el-form-item>
                <el-form-item label="标签名称" prop="name">
                    <le-input label="" style="padding: 0" v-model="form.name" />
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    companyId: '',
                    name: '',
                },
                id: '',
                rules:{
                    companyId: [{required: true, message:'请选择商户', trigger: 'change'}],
                    name: [{required: true, message:'请输入标签名称', trigger: 'blur'}],
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$Axios._post({
                            url: this.$Config.apiUrl.stationTagAdd,
                            method: "post",
                            params: {
                                ...this.form,
                            },
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$emit('addSuccess')
                                this.dialogVisible = false
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                        .catch(err=>{

                        })
                    }
                })
            }
        }
    }
</script>