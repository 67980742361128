<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">站点标签管理</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addLabel">添加标签</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-company-under-select label="所属商户" v-model="pageParam.params.companyId"></le-company-under-select>
                <le-input label="标签名称" v-model="pageParam.params.name" />
            </le-search-form>
            <le-pagview ref="stationLabelPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.stationLabel" :pageSizeDefault='10'>
                <el-table ref="stationLabel" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="stationName" label="标签名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="所属商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="使用站点数" min-width="120">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="toStationList(row)">{{ row.usingNum || '0' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="创建时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="创建人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <add-station-label ref="addStationLabel" @addSuccess='addSuccess'></add-station-label>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import addStationLabel from './child/add-station-label.vue';
    export default {
        components: { addStationLabel },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getStationTagList,
                    method: "post",
                    params: {
                        name: '',
                        companyId: '',
                    },
                    freshCtrl: 1,
                },
            }
        },
        created () {

        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data.map(res=>{
                    return {
                        ...res,
                    }
                });
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    companyId: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['stationLabel'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            addLabel () {
                this.$refs['addStationLabel'].dialogVisible = true
            },
            addSuccess () {
                this.pageParam.freshCtrl++;
            },
            toStationList (datas) {
                this.$router.push({
                    path: '/station/station-list',
                    query: {
                        labelId: datas.id
                    }
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>